import { useTranslation } from 'react-i18next'
import { Button } from 'src/components/ui/button'
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from 'src/components/ui/card'
import { Separator } from 'src/components/ui/separator'
import i18n from 'src/i18n'

const TermsOfServiceView = () => {
  const { t } = useTranslation()

  // Function to switch the language
  const changeLanguage = (lng: any) => {
    i18n.changeLanguage(lng)
  }

  // Fetch the content for all sections
  const content1: string[] = t('termsOfService.firstSection.content', {
    returnObjects: true,
  })
  const content2: string[] = t('termsOfService.secondSection.content', {
    returnObjects: true,
  })
  const content3: string[] = t('termsOfService.thirdSection.content', {
    returnObjects: true,
  })
  const content4: string[] = t('termsOfService.fourthSection.content', {
    returnObjects: true,
  })
  const content5: string[] = t('termsOfService.fifthSection.content', {
    returnObjects: true,
  })
  const content6: string[] = t('termsOfService.sixthSection.content', {
    returnObjects: true,
  })
  const content7: string[] = t('termsOfService.seventhSection.content', {
    returnObjects: true,
  })
  const content8: string[] = t('termsOfService.eighthSection.content', {
    returnObjects: true,
  })
  const content9: string[] = t('termsOfService.ninthSection.content', {
    returnObjects: true,
  })
  const content10: string[] = t('termsOfService.tenthSection.content', {
    returnObjects: true,
  })

  return (
    <div className="my-4 mx-2">
      {/* Language switch buttons */}
      <div className="flex flex-row flex-wrap gap-2 mb-2">
        <Button
          variant={i18n.language === 'fi' ? 'default' : 'outline'}
          onClick={() => changeLanguage('fi')}
        >
          Suomi
        </Button>
        <Button
          variant={i18n.language === 'en' ? 'default' : 'outline'}
          onClick={() => changeLanguage('en')}
        >
          English
        </Button>
      </div>

      {/* Terms of Service card */}
      <Card>
        <CardHeader>
          <CardTitle>{t('termsOfService.pageTitle')}</CardTitle>
        </CardHeader>
        <CardContent className="flex flex-col gap-3">
          <p>{t('termsOfService.description')}</p>

          {/* Section 1 */}
          <h3>{t('termsOfService.firstSection.title')}</h3>
          {content1.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}

          {/* Section 2 */}
          <h3>{t('termsOfService.secondSection.title')}</h3>
          {content2.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}

          {/* Section 3 */}
          <h3>{t('termsOfService.thirdSection.title')}</h3>
          {content3.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}

          {/* Section 4 */}
          <h3>{t('termsOfService.fourthSection.title')}</h3>
          {content4.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}

          {/* Section 5 */}
          <h3>{t('termsOfService.fifthSection.title')}</h3>
          {content5.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}

          {/* Section 6 */}
          <h3>{t('termsOfService.sixthSection.title')}</h3>
          {content6.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}

          {/* Section 7 */}
          <h3>{t('termsOfService.seventhSection.title')}</h3>
          {content7.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}

          {/* Section 8 */}
          <h3>{t('termsOfService.eighthSection.title')}</h3>
          {content8.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}

          {/* Section 9 */}
          <h3>{t('termsOfService.ninthSection.title')}</h3>
          {content9.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}

          {/* Section 10 */}
          <h3>{t('termsOfService.tenthSection.title')}</h3>
          {content10.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}

          <Separator className="my-2" />
          {t('termsOfService.customerAgreeance')}
        </CardContent>
      </Card>
    </div>
  )
}

export default TermsOfServiceView
