import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Blueprint, Bubble, UserProjectRoles } from 'src/lib/types'

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/components/ui/select'
import i18n from 'src/i18n'
import bubblesService from 'src/services/Bubbles/bubbles'
import AddOrUpdateBubbleDialog from './AddOrUpdateBubbleDialog'
import { Button } from '../ui/button'
import { toast } from 'react-toastify'
import blueprintsService from 'src/services/Blueprints/blueprints'
import AddOrUpdateBlueprintDialog from './AddOrUpdateBlueprintDialog'
import DisplaySingleBlueprintImage from './DisplaySingleBlueprintImage'
import { iconAndTextStyling } from 'src/constants'
import { Ellipsis, Trash2 } from 'lucide-react'
import {
  currentUserDataWithProjectRoleIsDefined,
  displayResponseErrorMessage,
} from 'src/lib/utils'
import { useConfirmaDialogStableTarget } from 'src/components/ui/confirmDialog'
import { useAppContext } from 'src/context/AppProvider'
import ErrorPage from 'src/views/ErrorPage/ErrorPage'
import SmallLoadingCircleOnly from '../Loading/SmallLoadingCircle'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog'
import { Separator } from '../ui/separator'
import { Card } from '../ui/card'

interface Props {
  blueprints: Blueprint[]
  selectedBlueprint: Blueprint | null
  handleSelection: (value: string) => void
  setBlueprints: Dispatch<SetStateAction<Blueprint[]>>
  setSelectedBlueprint: Dispatch<SetStateAction<Blueprint | null>>
}

const BlueprintDisplay = ({
  blueprints,
  selectedBlueprint,
  handleSelection,
  setBlueprints,
  setSelectedBlueprint,
}: Props) => {
  const [error, setError] = useState<string>('')
  const [selectedBlueprintBubbles, setSelectedBlueprintBubbles] = useState<
    Bubble[]
  >([])
  const [imageUrl, setImageUrl] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const { currentProject, currentUser } = useAppContext()
  const confirmDeletion = useConfirmaDialogStableTarget()

  useEffect(() => {
    const fetchBubbles = async () => {
      if (selectedBlueprint) {
        try {
          setLoading(true)
          const signedUrl = await blueprintsService.getSignedUrlByBlueprintId(
            selectedBlueprint.id,
            selectedBlueprint.project_id
          )
          const fetchedBubbles = await bubblesService.getBubblesByBlueprintId(
            selectedBlueprint.id,
            selectedBlueprint.project_id
          )
          if (fetchedBubbles.status === 200 && signedUrl) {
            setSelectedBlueprintBubbles(fetchedBubbles.data)
            setImageUrl(signedUrl)
          }
        } catch (error) {
          setError(i18n.t('errorFetchingBlueprints'))
        } finally {
          setLoading(false)
        }
      }
    }

    fetchBubbles()
  }, [selectedBlueprint])

  const handleDeleteBlueprint = async (projectId: string) => {
    if (!selectedBlueprint) {
      toast.error(i18n.t('noBlueprintSelected'))
      return
    }

    if (selectedBlueprintBubbles.length > 0) {
      toast.error(i18n.t('blueprintContainsBubbles'))
      return
    }

    const confirmed = await confirmDeletion({
      title: i18n.t('confirmDelete'),
      text: selectedBlueprint.title,
    })

    if (!confirmed) {
      return
    }

    try {
      const deletedBlueprintId = await blueprintsService.deleteBlueprintById(
        selectedBlueprint.id,
        projectId
      )
      if (deletedBlueprintId) {
        toast.success(i18n.t('successGeneric'))
        setBlueprints((oldBlueprints) =>
          oldBlueprints.filter((b) => b.id !== deletedBlueprintId)
        )
        setSelectedBlueprint(() => {
          const oldBlueprints = blueprints.filter(
            (b) => b.id !== deletedBlueprintId
          )
          return oldBlueprints.length > 0 ? oldBlueprints[0] : null
        })
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  if (error) {
    return <div>{error}</div>
  }

  if (!currentUserDataWithProjectRoleIsDefined(currentUser)) {
    return <ErrorPage />
  }

  if (!selectedBlueprint) {
    return (
      <div
        className="flex flex-col gap-2"
        data-testid="no-blueprints-found-container"
      >
        <p>{i18n.t('noBlueprintsFound')}</p>
        {currentUser.project_role === UserProjectRoles.ADMIN && (
          <div>
            <AddOrUpdateBlueprintDialog
              blueprint={null}
              setBlueprints={setBlueprints}
              setSelectedBlueprint={setSelectedBlueprint}
              imageUrl={imageUrl}
            />
          </div>
        )}
      </div>
    )
  }

  return (
    <Card
      className="w-full h-full"
      data-testid={`blueprint-display-${selectedBlueprint.title}`}
    >
      <div className="my-1 flex flex-row flex-wrap gap-2">
        <Select
          onValueChange={(value) => handleSelection(value)}
          value={selectedBlueprint.id}
        >
          <SelectTrigger
            className="w-[180px]"
            data-testid="change-selected-blueprint-trigger"
          >
            <SelectValue
              placeholder={selectedBlueprint?.title ?? i18n.t('blueprint')}
            />
          </SelectTrigger>
          <SelectContent>
            {blueprints.map((blueprint) => (
              <SelectItem
                key={blueprint.id}
                value={blueprint.id.toString()}
                data-testid={`select-current-blueprint-${blueprint.title}`}
              >
                {blueprint.title}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <AddOrUpdateBubbleDialog
          blueprint={selectedBlueprint}
          setSelectedBlueprintBubbles={setSelectedBlueprintBubbles}
          bubbles={selectedBlueprintBubbles}
          imageUrl={imageUrl}
        />
        {currentUser.project_role === UserProjectRoles.ADMIN && (
          <div className="items-end">
            <Dialog>
              <DialogTrigger asChild>
                <Button variant={'ghost'} data-testid="manage-blueprint-button">
                  <Ellipsis />
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>{i18n.t('functions')}</DialogTitle>
                </DialogHeader>
                <Separator className="my-3" />
                {currentUser.project_role === UserProjectRoles.ADMIN && (
                  <>
                    {blueprints.length < currentProject.max_blueprints ? (
                      <AddOrUpdateBlueprintDialog
                        blueprint={null}
                        setBlueprints={setBlueprints}
                        setSelectedBlueprint={setSelectedBlueprint}
                      />
                    ) : (
                      <div>
                        <p className="font-bold">
                          {i18n.t('maxBlueprintsAlreadyAdded')}
                        </p>
                        <a
                          href="https://www.gobosoft.fi/yhteystiedot"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="underline"
                        >
                          {i18n.t('requestMoreBlueprints')}
                        </a>
                      </div>
                    )}
                  </>
                )}
                <AddOrUpdateBlueprintDialog
                  blueprint={selectedBlueprint}
                  setBlueprints={setBlueprints}
                  setSelectedBlueprint={setSelectedBlueprint}
                  imageUrl={imageUrl}
                />
                <Button
                  data-testid={`delete-blueprint-button-${selectedBlueprint.title}`}
                  variant={'destructive'}
                  onClick={() => handleDeleteBlueprint(currentProject.id)}
                  className={iconAndTextStyling}
                >
                  <Trash2 /> {i18n.t('deleteBlueprint')}
                </Button>
              </DialogContent>
            </Dialog>
          </div>
        )}
      </div>
      {loading ? (
        <SmallLoadingCircleOnly />
      ) : (
        <div>
          <DisplaySingleBlueprintImage
            blueprint={selectedBlueprint}
            bubbles={selectedBlueprintBubbles}
            setBubbles={setSelectedBlueprintBubbles}
            imageSource={imageUrl}
          />
        </div>
      )}
    </Card>
  )
}

export default BlueprintDisplay
