import React, { FormEvent, useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog'
import { Button } from '../ui/button'
import { Label } from '../ui/label'
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from '../ui/select'
import userService from 'src/services/Users/users'
import { toast } from 'react-toastify'
import i18n from 'src/i18n'
import { v4 as uuidv4 } from 'uuid'
import { ForeignUserData, UserCustomershipRoles } from 'src/lib/types'
import modifyUserRolesService from 'src/services/ModifyUserRoles/modifyUserRolesService'
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover'
import { Check, ChevronsUpDown } from 'lucide-react'
import { InfoCircledIcon } from '@radix-ui/react-icons'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '../ui/command'
import { cn } from 'src/lib/utils'
import { useAppContext } from 'src/context/AppProvider'
import SmallLoadingCircleOnly from '../Loading/SmallLoadingCircle'
import { RenderCustomershipRolesInfo } from 'src/components/ModifyUserRoles/RenderCustomershipRolesInfo'

interface Props {
  customershipId: string
  fetchUsers: () => Promise<void>
}

interface EmailItem {
  id: string
  email: string
  role: string
}

const ModifyCustomershipUserRolesDialog = ({
  customershipId,
  fetchUsers,
}: Props) => {
  const [emailList, setEmailList] = useState<EmailItem[]>([])
  const [emailInput, setEmailInput] = useState<string>('')
  const [selectedRole, setSelectedRole] = useState<UserCustomershipRoles>(
    UserCustomershipRoles.MEMBER
  )
  const [suggestions, setSuggestions] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [users, setUsers] = useState<ForeignUserData[]>([])

  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [showRoleInfo, setShowRoleInfo] = useState<boolean>(false) // Toggle for role info visibility
  const [open, setOpen] = useState<boolean>(false)

  const { currentUser } = useAppContext()

  const currentUserEmail = currentUser.userData.email

  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  const getCustomershipUsers = async () => {
    try {
      setLoading(true)
      const response =
        await userService.getUsersByCustomershipId(customershipId)
      if (response.status === 200) {
        const fetchedUsers = response.data as ForeignUserData[]
        setUsers(fetchedUsers)
        setSuggestions(
          fetchedUsers
            .map((user) => user.userData.email)
            .filter((email) => email !== currentUserEmail)
        )
      }
    } catch {
      setSuggestions([])
    } finally {
      setLoading(false)
    }
  }

  const addSingleEmail = () => {
    if (!emailInput.match(emailPattern)) {
      toast.error(i18n.t('roleModification.notification.invalidFormat'))
      return
    }

    if (currentUserEmail && emailInput === currentUserEmail) {
      toast.error(i18n.t('roleModification.notification.cannotModifySelf'))
      return
    }

    if (!suggestions.includes(emailInput)) {
      toast.error(i18n.t('roleModification.notification.emailNotInProject'))
      return
    }

    const user = users.find((user) => user.userData.email === emailInput)
    const userRole = user?.customership_role

    if (userRole) {
      if (selectedRole === userRole) {
        toast.warn(i18n.t('roleModification.notification.sameRoleWarning'))
        return
      } else if (
        userRole === UserCustomershipRoles.ADMIN &&
        selectedRole === UserCustomershipRoles.ADMIN
      ) {
        toast.warn(i18n.t('roleModification.notification.alreadyAdmin'))
        return
      }
    }

    const newEmailItem = {
      id: uuidv4(),
      email: emailInput,
      role: selectedRole,
    }

    setEmailList([...emailList, newEmailItem])
    setEmailInput('')
    setSuggestions([])
  }

  const removeEmail = (id: string) => {
    setEmailList(emailList.filter((item) => item.id !== id))
  }

  const handlePromoteMembers = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const members = emailList.map((item) => ({
      email: item.email,
      role: item.role,
    }))

    if (!members.length || !customershipId) {
      toast.error(i18n.t('roleModification.notification.noValidEmailsProvided'))
      return
    }
    try {
      const response = await modifyUserRolesService.modifyCustomershipUserRole(
        { members },
        customershipId
      )
      if (response.status === 200) {
        if (
          response.data.failed_emails &&
          response.data.failed_emails.length > 0
        ) {
          toast.warning(i18n.t('roleModification.notification.partialSuccess'))
        } else {
          toast.success(i18n.t('roleModification.notification.success'))
        }
        await fetchUsers()
        setEmailList([])
        setDialogOpen(false)
      }
    } catch (error) {
      console.error('Failed to promote members:', error)
      toast.error(i18n.t('roleModification.notification.errorOccurred'))
    }
  }

  return (
    <Dialog
      open={dialogOpen}
      onOpenChange={async (open) => {
        if (open) {
          setDialogOpen(true)
          await getCustomershipUsers()
        } else {
          setDialogOpen(false)
        }
      }}
    >
      <DialogTrigger
        asChild
        data-testid="modify-customership-roles-dialog-trigger"
      >
        <Button>{i18n.t('roleModification.buttons.modifyRoles')}</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-xl p-4">
        <DialogHeader>
          <DialogTitle>{i18n.t('roleModification.title')}</DialogTitle>
        </DialogHeader>
        <p className="text-sm mb-4">
          {i18n.t('roleModification.customershipInstructions')}
          <button
            type="button"
            onClick={() => setShowRoleInfo(!showRoleInfo)}
            className="ml-1 inline-flex items-center align-middle"
          >
            <InfoCircledIcon className="w-4 h-4 text-gray-600" />
          </button>
        </p>
        {showRoleInfo && <RenderCustomershipRolesInfo />}
        <form className="flex flex-col gap-3" onSubmit={handlePromoteMembers}>
          <Label htmlFor="singleEmail">
            {i18n.t('roleModification.tableHeader')}
          </Label>
          <div className="flex gap-2">
            <Popover open={open} onOpenChange={setOpen} modal={true}>
              <PopoverTrigger asChild data-testid="role-email-input-trigger">
                <Button
                  variant="outline"
                  role="combobox"
                  aria-expanded={open}
                  className="w-full justify-between"
                >
                  {emailInput
                    ? suggestions.find((sug) => sug === emailInput)
                    : i18n.t('roleModification.placeHolder')}
                  <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="p-0" align="start">
                <Command className="max-h-[40vh]">
                  <CommandInput
                    data-testid="role-email-input"
                    placeholder={i18n.t('roleModification.placeHolder')}
                  />
                  <CommandList>
                    <CommandEmpty>
                      {i18n.t('roleModification.noSearchResults')}
                    </CommandEmpty>
                    <CommandGroup>
                      {suggestions.map((sug, index) => (
                        <CommandItem
                          key={index}
                          value={sug}
                          data-testid={`select-email-${sug}`}
                          onSelect={(currentValue) => {
                            setEmailInput(
                              currentValue === emailInput ? '' : currentValue
                            )
                            setOpen(false)
                          }}
                        >
                          <Check
                            className={cn(
                              'mr-2 h-4 w-4',
                              emailInput === sug ? 'opacity-100' : 'opacity-0'
                            )}
                          />
                          {sug}
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
            <Select
              onValueChange={(value: string) =>
                setSelectedRole(value as UserCustomershipRoles)
              }
              value={selectedRole}
            >
              <SelectTrigger
                className="w-[140px] bg-black text-white rounded-md"
                data-testid="select-role-trigger"
              >
                <SelectValue placeholder={i18n.t('selectRole')} />
              </SelectTrigger>
              <SelectContent>
                <SelectItem
                  value={UserCustomershipRoles.MEMBER}
                  data-testid={`select-role-${UserCustomershipRoles.MEMBER}`}
                >
                  {i18n.t('roleModification.dropdown.member')}
                </SelectItem>
                <SelectItem
                  value={UserCustomershipRoles.ADMIN}
                  data-testid={`select-role-${UserCustomershipRoles.ADMIN}`}
                >
                  {i18n.t('roleModification.dropdown.admin')}
                </SelectItem>
              </SelectContent>
            </Select>
            <Button
              type="button"
              onClick={addSingleEmail}
              data-testid="add-single-email-button"
            >
              {i18n.t('roleModification.buttons.addmembers')}
            </Button>
          </div>
          {loading && <SmallLoadingCircleOnly />}
          <div className="bg-white rounded-md p-2 h-40 overflow-y-auto border">
            <ul>
              {emailList.map((item) => (
                <li
                  key={item.id}
                  className="flex justify-between p-2 border-b last:border-b-0"
                >
                  <span>
                    {item.email} -{' '}
                    {i18n.t(
                      `roleModification.dropdown.${item.role?.toLowerCase()}`
                    )}
                  </span>
                  <Button
                    type="button"
                    onClick={() => removeEmail(item.id)}
                    data-testid={`remove-email-button-${item.email}`}
                  >
                    &times;
                  </Button>
                </li>
              ))}
            </ul>
          </div>
          <DialogFooter>
            <Button type="submit" data-testid="submit-roles-button">
              {i18n.t('roleModification.buttons.submitRoles')}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default ModifyCustomershipUserRolesDialog
