import { Undo2, ZoomIn, ZoomOut } from 'lucide-react'
import { useState } from 'react'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import SmallLoadingCircleOnly from 'src/components/Loading/SmallLoadingCircle'
import { Button } from 'src/components/ui/button'
import { Blueprint, Bubble } from 'src/lib/types'
import DemoSingleBubbleDialog from './DemoSingleBubbleDialog'

interface Props {
  blueprint: Blueprint
  imageSource: string
  bubbles?: Bubble[]
}

const DemoBlueprintDisplay = ({ blueprint, imageSource, bubbles }: Props) => {
  const initialScale = 1
  const [scale, setScale] = useState<number>(initialScale)
  const [imageLoading, setImageLoading] = useState<boolean>(true)

  const handleScaleChange = (event: any) => {
    setScale(event.instance.transformState.scale)
  }

  const handleImageLoad = (resetTransform: () => void) => {
    resetTransform()
    setImageLoading(false)
  }

  return (
    <TransformWrapper
      doubleClick={{
        excluded: [],
        step: 0.7,
      }}
      panning={{
        activationKeys: [],
        excluded: [],
      }}
      pinch={{
        excluded: [],
        step: 5,
      }}
      wheel={{
        activationKeys: [],
        excluded: [],
        smoothStep: 0.001,
        step: 0.2,
      }}
      minScale={0.05}
      maxScale={100}
      initialScale={initialScale}
      centerOnInit
      onTransformed={(e) => handleScaleChange(e)}
    >
      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <div className="relative w-full h-[80svh] md:h-full">
          <div className="absolute top-2 right-2 z-10 flex flex-col gap-2">
            <Button
              type="button"
              variant={'secondary'}
              onClick={() => zoomIn()}
            >
              <ZoomIn />
            </Button>
            <Button
              type="button"
              variant={'secondary'}
              onClick={() => zoomOut()}
            >
              <ZoomOut />
            </Button>
            <Button
              type="button"
              variant={'secondary'}
              onClick={() => resetTransform()}
            >
              <Undo2 />
            </Button>
          </div>
          <div className="w-full h-full">
            <TransformComponent
              wrapperStyle={{
                height: '100%',
                width: '100%',
              }}
            >
              {imageLoading && <SmallLoadingCircleOnly />}
              <div className="relative w-full h-full">
                <img
                  src={imageSource}
                  data-testid={`blueprint-image-${blueprint?.title}`}
                  alt="blueprint"
                  className={`md:max-h-[65vh] 2xl:max-h-[70vh] block object-contain transition-opacity duration-300 ${imageLoading ? 'opacity-0' : 'opacity-100'}`}
                  draggable={false}
                  style={{
                    pointerEvents: 'auto', // Ensure pointer events are enabled
                  }}
                  onLoad={() => handleImageLoad(resetTransform)}
                />
                {bubbles?.map((bubble) => (
                  <div
                    className={`transition-opacity duration-300 ${imageLoading ? 'opacity-0' : 'opacity-100'}`}
                    key={bubble.id}
                    style={{
                      position: 'absolute',
                      top: `${bubble.y * 100}%`,
                      left: `${bubble.x * 100}%`,
                      transform: `translate(-50%, -50%) scale(${1 / scale})`, // Adjust bubble scaling based on image scale
                    }}
                  >
                    <DemoSingleBubbleDialog bubble={bubble} />
                  </div>
                ))}
              </div>
            </TransformComponent>
          </div>
        </div>
      )}
    </TransformWrapper>
  )
}

export default DemoBlueprintDisplay
