import { AxiosResponse } from 'axios'
import axiosInstance from 'src/axiosConfig'
import { Bubble } from 'src/lib/types'

const baseUrl = '/api/bubbles'

interface BubbleData {
  title: string
  x: number
  y: number
}

export type SearchBubblesProps = {
  search: string
  category: string
  unit: string
}

const addNewBubble = async (
  bubbleData: BubbleData,
  project_id: string,
  blueprint_id: string
) => {
  const request = await axiosInstance.post(
    `${baseUrl}/add-new/blueprints/${blueprint_id}/projects/${project_id}`,
    bubbleData
  )
  return request.data as Bubble
}

const getBubblesByBlueprintId = async (
  blueprint_id: string,
  project_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.get(
    `${baseUrl}/blueprints/${blueprint_id}/projects/${project_id}`
  )
  return response
}

const deleteBubbleById = async (bubbleId: string, project_id: string) => {
  const request = await axiosInstance.delete(
    `${baseUrl}/${bubbleId}/projects/${project_id}`
  )
  return request.data
}

const updateBubbleById = async (updatedBubble: Bubble, project_id: string) => {
  const { id, ...data } = updatedBubble
  const request = await axiosInstance.put(
    `${baseUrl}/${id}/projects/${project_id}`,
    data
  )
  return request.data
}

const getBubbleById = async (bubbleId: string, project_id: string) => {
  const request = await axiosInstance.get(
    `${baseUrl}/${bubbleId}/projects/${project_id}`
  )
  return request.data
}

export default {
  addNewBubble,
  getBubblesByBlueprintId,
  deleteBubbleById,
  updateBubbleById,
  getBubbleById,
}
