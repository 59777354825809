import { Ellipsis } from 'lucide-react'
import { useState } from 'react'
import { Button } from 'src/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu'
import i18n from 'src/i18n'

const DemoManageItemsButton = () => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant={'ghost'}>
          <Ellipsis />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>{i18n.t('manage')}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DemoManageItemDialog buttonText={i18n.t('markAsWaste')} />
        <DropdownMenuSeparator />
        <DemoManageItemDialog buttonText={i18n.t('moveItems')} />
        <DropdownMenuSeparator />
        <DemoManageItemDialog buttonText={i18n.t('delete')} />
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

const DemoManageItemDialog = ({ buttonText }: { buttonText: string }) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger asChild>
        <DropdownMenuItem
          onSelect={(event) => {
            event.preventDefault()
          }}
        >
          {buttonText}
        </DropdownMenuItem>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{buttonText}</DialogTitle>
          <DialogDescription>
            {i18n.t('demoDisplay.logInToProceedOperation')}
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}

export default DemoManageItemsButton
