import React, { useEffect, useState } from 'react'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from 'src/components/ui/card'
import { Label } from 'src/components/ui/label'
import i18n from 'src/i18n'
import { User } from 'src/lib/types'
import usersService from 'src/services/Users/users'

const ProfileView = () => {
  const [user, setUser] = useState<User>()

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await usersService.getCurrentUser()
        if (user) {
          setUser(user)
        }
      } catch (error) {
        console.log(error)
      }
    }

    fetchUser()
  }, [])

  if (!user) {
    return null
  }

  return (
    <div className="flex flex-col gap-2">
      <Card x-chunk="dashboard-04-chunk-1">
        <CardHeader>
          <CardTitle>{i18n.t('profile.userDetails')}</CardTitle>
          <CardDescription>
            {i18n.t('profile.userDetailsDescription')}
          </CardDescription>
        </CardHeader>
        <CardContent>
          <p>
            {i18n.t('email')}: {user.email}
          </p>
          <p>
            {i18n.t('phone')}: {user.phone}
          </p>
          <p>
            {i18n.t('language')}: {user.language ?? '-'}
          </p>
          <p>
            {i18n.t('organization')}: {user.organization ?? '-'}
          </p>
          <p>
            {i18n.t('createdAt')}: {user.created_at?.toString()}
          </p>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>
            {i18n.t('termsOfService.pageTitle')} {i18n.t('and')}{' '}
            {i18n.t('privacyStatement')}
          </CardTitle>
        </CardHeader>
        <CardContent className="flex flex-col gap-2">
          <Label>
            <a href="/terms-of-service" target="_blank" className="underline">
              {i18n.t('termsOfService.pageTitle')}
            </a>
          </Label>
          <Label>
            <a
              href="https://www.gobosoft.fi/tietosuojaseloste/"
              target="_blank"
              className="underline"
            >
              {i18n.t('privacyStatement')}
            </a>
          </Label>
        </CardContent>
      </Card>
    </div>
  )
}

export default ProfileView
