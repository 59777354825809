import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../ui/table'
import i18n from 'src/i18n'
import { Button } from '../ui/button'
import { Check, X } from 'lucide-react'
import { Customership, CustomershipInvitation } from 'src/lib/types'
import {
  displayResponseErrorMessage,
  formatDateLocaleExact,
} from 'src/lib/utils'
import { useConfirmaDialogStableTarget } from 'src/components/ui/confirmDialog'
import customershipInvitationService from 'src/services/CustomershipInvitations/customershipInvitationService'
import { toast } from 'react-toastify'
import customershipService from 'src/services/Customerships/customershipService'
import { useAppContext } from 'src/context/AppProvider'
import SmallLoadingCircleOnly from '../Loading/SmallLoadingCircle'
import { useSearchParams } from 'react-router-dom'

interface Props {
  setCustomerships: Dispatch<SetStateAction<Customership[]>>
}

const CustomershipInvitationsTable = ({ setCustomerships }: Props) => {
  const [invitations, setInvitations] = useState<CustomershipInvitation[]>([])
  const { currentUser } = useAppContext()
  const [loading, setLoading] = useState<boolean>(false)
  const [searchParams] = useSearchParams()
  const confirmDeletion = useConfirmaDialogStableTarget()

  const fetchData = async () => {
    try {
      setLoading(true)
      // Ensure invitation updates occur before fetching invitations
      const updated =
        await customershipInvitationService.updateUserInvitations()

      if (updated) {
        // Only fetch pending invitations after successful update
        const pendingInvitations =
          await customershipInvitationService.getPendingInvitations()
        setInvitations(pendingInvitations)
      } else {
        console.error('Failed to update invitations before fetching')
      }
    } catch (error) {
      console.error('Error fetching projects or invitations:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleAccept = async (invitationId: string) => {
    try {
      const confirmed = await confirmDeletion({
        title: i18n.t('invitation.actions.confirmCustomershipAccept'),
      })
      if (!confirmed) {
        return
      }
      const success =
        await customershipInvitationService.acceptInvitation(invitationId) // Implement this function to call your API
      if (success) {
        setInvitations(
          invitations.filter((invitation) => invitation.id !== invitationId)
        )
        // update customerships
        const page = searchParams.get('page')
        const searchQuery = searchParams.get('search') ?? ''
        const response = await customershipService.searchUsersCustomerships({
          search: searchQuery,
          page: page ? parseInt(page) : 1,
          results_per_page: 3,
        })
        if (response.status === 200) {
          const responseCustomerships = response.data.customerships
          setCustomerships(responseCustomerships.data as Customership[])
          toast.success(i18n.t('invitation.responses.accepted'))
        }
      } else {
        toast.error(i18n.t('invitation.responses.acceptedError'))
      }
    } catch (e) {
      displayResponseErrorMessage(e)
    }
  }

  const handleDecline = async (invitationId: string) => {
    try {
      const confirmed = await confirmDeletion({
        title: i18n.t('invitation.actions.confirmCustomershipDecline'),
      })
      if (!confirmed) {
        return
      }
      const success =
        await customershipInvitationService.declineInvitation(invitationId) // Implement this function to call your API
      if (success) {
        setInvitations(
          invitations.filter((invitation) => invitation.id !== invitationId)
        )
        toast.success(i18n.t('invitation.responses.declined'))
      } else {
        toast.error(i18n.t('invitation.responses.declinedError'))
      }
    } catch (e) {
      displayResponseErrorMessage(e)
    }
  }

  return (
    <>
      {loading ? (
        <SmallLoadingCircleOnly />
      ) : (
        <div>
          <h1>{i18n.t('pendingCustomershipInvitations')}</h1>
          {invitations && invitations.length > 0 ? (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[200px]">
                    {i18n.t('invitation.tableHeaders.customership')}
                  </TableHead>
                  <TableHead className="w-[100px]">
                    {i18n.t('invitation.tableHeaders.sent')}
                  </TableHead>
                  <TableHead className="w-[100px]">
                    {i18n.t('invitation.tableHeaders.actions')}
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {invitations.map((invitation, index) => (
                  <TableRow
                    key={index}
                    data-testid={`customership-invitations-table-row-${invitation.business_id}`}
                  >
                    <TableCell>
                      <p>
                        {invitation.company_name} ({invitation.business_id})
                      </p>
                    </TableCell>
                    <TableCell>
                      <p>
                        {formatDateLocaleExact(invitation.sent, currentUser)}
                      </p>
                    </TableCell>
                    <TableCell className="flex gap-1">
                      <Button
                        data-testid={`accept-customership-invitation-${invitation.business_id}`}
                        onClick={() => handleAccept(invitation.id)}
                        title={i18n.t('invitation.actions.accept')}
                      >
                        <Check />
                      </Button>
                      <Button
                        data-testid={`decline-customership-invitation-${invitation.business_id}`}
                        onClick={() => handleDecline(invitation.id)}
                        title={i18n.t('invitation.actions.decline')}
                      >
                        <X />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <div>
              <p>{i18n.t('noPendingInvitations')}</p>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default CustomershipInvitationsTable
