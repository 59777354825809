import React from 'react'
import { cn } from 'src/lib/utils'
import i18n from 'src/i18n'

interface CustomershipRoleInfo {
  title: string
  member: string
  memberInfo: string[]
  admin: string
  adminInfo: string[]
}

export const RenderCustomershipRolesInfo: React.FC = () => {
  const customershipRoleInfo = i18n.t('userLevels.infoAboutCustomershipRoles', {
    returnObjects: true,
  }) as CustomershipRoleInfo

  return (
    <div
      className={cn(
        'z-50 w-full rounded-md border bg-popover p-4 text-popover-foreground shadow-md',
        'text-sm leading-relaxed'
      )}
    >
      <p className="font-semibold">{customershipRoleInfo.title}</p>

      <div className="mt-2">
        <p className="font-semibold">{customershipRoleInfo.member}:</p>
        <ul className="list-disc list-inside pl-4 text-sm">
          {customershipRoleInfo.memberInfo?.map((item, idx) => (
            <li key={idx}>{item}</li>
          ))}
        </ul>
      </div>

      <div className="mt-4">
        <p className="font-semibold">{customershipRoleInfo.admin}:</p>
        <ul className="list-disc list-inside pl-4 text-sm">
          {customershipRoleInfo.adminInfo?.map((item, idx) => (
            <li key={idx}>{item}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}
