import React, { Dispatch, SetStateAction } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../ui/table'
import { Item, ItemLastUpdateType } from 'src/lib/types'
import i18n from 'src/i18n'
import { formatDateLocaleExact } from 'src/lib/utils'
import ManageItemsButton from '../Buttons/ManageItemsButton'
import ShowLocationOnMapDialog from './ShowLocationOnMapDialog'
import AddOrUpdateItemDialog from '../Bubbles/AddOrUpdateItemDialog'
import SingleUserInfoDialog from '../Users/SingleUserInfoDialog'
import { useAppContext } from 'src/context/AppProvider'

interface Props {
  items: Item[]
  setItems: Dispatch<SetStateAction<Item[]>>
  showMapButton?: boolean
  displayLatestChangeInfo?: boolean
  tableDivClassName?: string
}

const ItemsTable = ({
  items,
  setItems,
  showMapButton,
  displayLatestChangeInfo,
  tableDivClassName,
}: Props) => {
  const { currentUser } = useAppContext()
  return (
    <Table tableDivClassName={tableDivClassName}>
      <TableHeader>
        <TableRow>
          <TableHead>{i18n.t('product')}</TableHead>
          <TableHead>{i18n.t('amount')}</TableHead>
          {displayLatestChangeInfo && (
            <TableHead>{i18n.t('latestChange')}</TableHead>
          )}
          <TableHead>{i18n.t('unit')}</TableHead>
          <TableHead>{i18n.t('category')}</TableHead>
          <TableHead>{i18n.t('lastModified')}</TableHead>
          <TableHead>{i18n.t('manage')}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {items.map((item) => (
          <TableRow key={item.id} data-testid={`items-table-row-${item.name}`}>
            <TableCell className="font-medium">{item.name}</TableCell>
            <TableCell>{item.amount}</TableCell>
            {displayLatestChangeInfo && (
              <TableCell data-testid={`latest-change-info-${item.name}`}>
                {(item.last_update_type ===
                  ItemLastUpdateType.INCREASE_AMOUNT ||
                  item.last_update_type ===
                    ItemLastUpdateType.DECREASE_AMOUNT ||
                  item.last_update_type === ItemLastUpdateType.ADD_WASTE ||
                  item.last_update_type === ItemLastUpdateType.MOVE) &&
                item.last_update_amount
                  ? `${item.last_update_amount > 0 ? '+' : ''}${item.last_update_amount} ${
                      item.last_update_type === ItemLastUpdateType.ADD_WASTE
                        ? `(${i18n.t('addedWaste')})`
                        : item.last_update_type === ItemLastUpdateType.MOVE
                          ? `(${i18n.t('itemsMoved')})`
                          : ''
                    }`
                  : item.last_update_type === ItemLastUpdateType.CREATION
                    ? `${i18n.t('itemCreated')}`
                    : item.last_update_type === ItemLastUpdateType.MOVE
                      ? `${i18n.t('itemsMoved')}`
                      : null}
              </TableCell>
            )}
            <TableCell>{item.unit}</TableCell>
            <TableCell>{item.category}</TableCell>
            <TableCell>
              {item.latest_editor_email ? (
                <div>
                  <SingleUserInfoDialog email={item.latest_editor_email} />
                </div>
              ) : (
                <p>{i18n.t('notFoundNA')}</p>
              )}
              {formatDateLocaleExact(item.updated_at.toString(), currentUser)}
            </TableCell>
            <TableCell className="flex gap-1">
              <AddOrUpdateItemDialog item={item} setItems={setItems} />
              {showMapButton && <ShowLocationOnMapDialog item={item} />}
              <ManageItemsButton item={item} setItems={setItems} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default ItemsTable
