import { Home } from 'lucide-react'
import { Link, Outlet } from 'react-router-dom'
import BreadcrumbsCustomershipPortal from 'src/components/Customerships/BreadcrumbsCustomershipPortal'
import i18n from 'src/i18n'

const CustomershipsLayout = () => {
  return (
    <div className="flex min-h-screen w-full flex-col">
      <main className="flex min-h-[calc(100vh_-_theme(spacing.16))] flex-1 flex-col gap-4 bg-muted/40 p-4 md:gap-8 md:p-10">
        <div>
          <div>
            <Link
              to={i18n.t('paths.mainscreen')}
              className="flex items-center gap-1"
            >
              <Home />
              {i18n.t('backToMainscreen')}
            </Link>
          </div>
          <h1 className="text-3xl font-semibold mt-2">
            {i18n.t('customershipPortal')}
          </h1>
        </div>
        <div className="mx-auto w-full max-w-6xl grid grid-cols-1 md:grid-cols-[180px_1fr] lg:grid-cols-[250px_1fr] gap-6">
          <nav
            className="grid gap-4 text-sm text-muted-foreground"
            x-chunk="dashboard-04-chunk-0"
          >
            <div>
              <Link
                to={i18n.t('paths.customershipPortal')}
                className="font-semibold text-black"
                data-testid="customership-portal-sidenav-link-customershipPortal"
              >
                {i18n.t('customershipPortal')}
              </Link>
            </div>
          </nav>
          <div className="flex flex-col gap-6">
            <BreadcrumbsCustomershipPortal />
            <Outlet />
          </div>
        </div>
      </main>
    </div>
  )
}

export default CustomershipsLayout
