import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { toast } from 'react-toastify'
import CustomershipInvitationStatusDialog from 'src/components/CustomershipInvitations/CustomershipInvitationStatusDialog'
import SmallLoadingCircleOnly from 'src/components/Loading/SmallLoadingCircle'
import ModifyCustomershipUserRolesDialog from 'src/components/ModifyUserRoles/ModifyCustomershipUserRolesDialog'
import Pagination from 'src/components/Pagination'
import CustomershipUsersTable from 'src/components/Users/CustomershipUsersTable'
import SearchUsers from 'src/components/Users/SearchUsers'
import { Button } from 'src/components/ui/button'
import i18n from 'src/i18n'
import { ForeignUserData, UserCustomershipRoles } from 'src/lib/types'
import { displayResponseErrorMessage } from 'src/lib/utils'
import { useConfirmaDialogStableTarget } from 'src/components/ui/confirmDialog'
import customershipService from 'src/services/Customerships/customershipService'
import usersService from 'src/services/Users/users'
import ErrorPage from '../ErrorPage/ErrorPage'
import { useSearchParams } from 'react-router-dom'
import { Separator } from 'src/components/ui/separator'
import { useAppContext } from 'src/context/AppProvider'
import { DEFAULT_SEARCH_RESULTS } from 'src/constants'

const CustomershipParticipantsView = () => {
  const { customershipId } = useParams()
  const [searchParams] = useSearchParams()
  const [users, setUsers] = useState<ForeignUserData[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [totalResults, setTotalResults] = useState<number>(0)
  const navigate = useNavigate()
  const confirmDeletion = useConfirmaDialogStableTarget()

  const { currentUser } = useAppContext()

  const resultsPerPage = parseInt(
    searchParams.get('resultsPerPage') ?? DEFAULT_SEARCH_RESULTS.toString()
  )

  const currentUserRole = currentUser.customership_role

  const handleDeleteCurrentUserFromCustomership = async (
    customershipId: string
  ) => {
    const confirmed = await confirmDeletion({
      title: i18n.t('confirmDelete'),
    })
    if (!confirmed) {
      return
    }

    try {
      const response =
        await customershipService.deleteCurrentUserFromCustomership(
          customershipId
        )
      if (response.status === 200) {
        navigate(i18n.t('paths.customershipPortal'))
        toast.success(i18n.t('successGeneric'))
      }
    } catch (error: any) {
      // Extract and display the error message from the response
      displayResponseErrorMessage(error)
    }
  }

  async function fetchUsers() {
    if (customershipId) {
      try {
        setLoading(true)
        const page = searchParams.get('page')
        const searchQuery = searchParams.get('search') ?? ''
        const role = searchParams.get('role') ?? ''
        const response = await usersService.searchUsersByCustomershipId(
          customershipId,
          {
            page: page ? parseInt(page) : 1,
            search: searchQuery,
            role: role !== 'null' ? (role as UserCustomershipRoles) : undefined,
            results_per_page: resultsPerPage,
          }
        )
        if (response.status === 200) {
          setUsers(response.data.users)
          setTotalResults(response.data.totalResults)
        }
      } catch (error) {
        displayResponseErrorMessage(error)
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    fetchUsers()
  }, [searchParams])

  if (!customershipId) {
    return <ErrorPage />
  }

  return (
    <div className="mx-2">
      <div className="flex flex-row flex-wrap gap-2 justify-between">
        <h1>{i18n.t('customershipParticipants')}</h1>
        <div className="flex flex-col gap-2">
          <Button
            data-testid="delete-current-user-from-customership"
            variant={'destructive'}
            onClick={() =>
              handleDeleteCurrentUserFromCustomership(customershipId)
            }
          >
            {i18n.t('leaveCustomership')}
          </Button>
          {currentUserRole === UserCustomershipRoles.ADMIN && (
            <>
              <ModifyCustomershipUserRolesDialog
                customershipId={customershipId}
                fetchUsers={fetchUsers}
              />
              <CustomershipInvitationStatusDialog
                customershipId={customershipId}
              />
            </>
          )}
        </div>
      </div>
      <Separator className="my-3" />
      <div className="my-3">
        <SearchUsers />
      </div>
      {loading ? (
        <SmallLoadingCircleOnly />
      ) : (
        <div>
          <p className="mb-3">
            {i18n.t('totalSearchResults')}: {totalResults}
          </p>
          <CustomershipUsersTable users={users} setUsers={setUsers} />
        </div>
      )}
      <div className="text-center my-2">
        <Pagination totalPages={Math.ceil(totalResults / resultsPerPage)} />
      </div>
    </div>
  )
}

export default CustomershipParticipantsView
