import { AxiosResponse } from 'axios'
import axiosInstance from 'src/axiosConfig'
import {
  Item,
  SearchItemResults,
  SearchItemUtils,
  SearchOutOfStockItemResults,
  SearchWasteItemResults,
} from 'src/lib/types'

const baseUrl = '/api/items'

interface AddItemData {
  name: string
  amount: number
  unit: string
  category: string | undefined
}

export type SearchItemsProps = {
  page: number
  search: string
  category: string
  unit: string
  sort: string
  items_per_page: number
}

export type SearchAllLocationsProps = {
  search: string
  category: string
  unit: string
}

const addNewItem = async (
  itemData: AddItemData,
  project_id: string,
  bubble_id: string
) => {
  const request = await axiosInstance.post(
    `${baseUrl}/add-new/bubbles/${bubble_id}/projects/${project_id}`,
    itemData
  )
  return request.data as Item
}

const getItemsByBubbleId = async (bubble_id: string, project_id: string) => {
  const request = await axiosInstance.get(
    `${baseUrl}/bubbles/${bubble_id}/projects/${project_id}`
  )
  return request.data as Item[]
}

const deleteItemById = async (itemId: string, project_id: string) => {
  const request = await axiosInstance.delete(
    `${baseUrl}/${itemId}/projects/${project_id}/mark-deleted`
  )
  return request.data
}

const markItemOutOfStockById = async (itemId: string, project_id: string) => {
  const request = await axiosInstance.post(
    `${baseUrl}/${itemId}/projects/${project_id}/mark-out-of-stock`
  )
  return request.data
}

const updateItemById = async (updatedItem: Item, project_id: string) => {
  const { id, ...data } = updatedItem
  const request = await axiosInstance.put(
    `${baseUrl}/${id}/projects/${project_id}`,
    data
  )
  return request.data
}

const searchItemsByProjectId = async (
  project_id: string,
  searchProps: SearchItemsProps
) => {
  const request = await axiosInstance.post(
    `${baseUrl}/projects/${project_id}/search`,
    searchProps
  )
  return request.data as SearchItemResults
}

const searchWasteItemsByProjectId = async (
  project_id: string,
  searchProps: SearchItemsProps
) => {
  const request = await axiosInstance.post(
    `${baseUrl}/waste-items/projects/${project_id}/search`,
    searchProps
  )
  return request.data as SearchWasteItemResults
}

const searchOutOfStockItemsByProjectId = async (
  project_id: string,
  searchProps: SearchItemsProps
) => {
  const request = await axiosInstance.post(
    `${baseUrl}/out-of-stock-items/projects/${project_id}/search`,
    searchProps
  )
  return request.data as SearchOutOfStockItemResults
}

const getSearchUtilsByProjectId = async (project_id: string) => {
  const request = await axiosInstance.get(
    `${baseUrl}/projects/${project_id}/search-utils`
  )
  return request.data as SearchItemUtils
}

const getAllLocationsData = async (
  searchData: SearchAllLocationsProps,
  project_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.post(
    `${baseUrl}/search-all-locations/projects/${project_id}`,
    searchData
  )
  return response
}

const markItemToWasteByIdAndAmount = async (
  waste_amount: number,
  itemId: string,
  project_id: string
): Promise<AxiosResponse> => {
  const response = await axiosInstance.post(
    `${baseUrl}/${itemId}/projects/${project_id}/add-to-waste`,
    {
      waste_amount: waste_amount,
    }
  )
  return response
}

interface MoveItemRequest {
  amount: number
}

const moveItem = async (
  item_id: string,
  project_id: string,
  bubble_id: string,
  requestBody: MoveItemRequest
): Promise<AxiosResponse> => {
  const response = await axiosInstance.put(
    `${baseUrl}/${item_id}/projects/${project_id}/move-to-bubble/${bubble_id}`,
    requestBody
  )
  return response
}

export default {
  addNewItem,
  getItemsByBubbleId,
  deleteItemById,
  updateItemById,
  searchItemsByProjectId,
  getSearchUtilsByProjectId,
  getAllLocationsData,
  markItemToWasteByIdAndAmount,
  markItemOutOfStockById,
  searchWasteItemsByProjectId,
  searchOutOfStockItemsByProjectId,
  moveItem,
}
