import React, { Dispatch, SetStateAction, useState, useEffect } from 'react'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu'
import { Button } from '../ui/button'
import i18n from 'src/i18n'
import { Item } from 'src/lib/types'
import { toast } from 'react-toastify'
import itemsService from 'src/services/Items/items'
import { Ellipsis } from 'lucide-react'
import { displayResponseErrorMessage } from 'src/lib/utils'
import { useConfirmDialogUnStableTarget } from 'src/components/ui/confirmDialog'
import { useConfirmaDialogStableTarget } from 'src/components/ui/confirmDialog'
import { useAppContext } from 'src/context/AppProvider'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog'
import { Separator } from '../ui/separator'
import { Field, Form, Formik } from 'formik'
import PendingSubmitButton from './PendingSubmitButton'
import { Input } from '../ui/input'
import { Label } from '../ui/label'
import FormErrorMessage from '../Forms/FormErrorMessage'
import { validationSchemaAddWasteItem } from 'src/lib/validationSchemas'
import MoveItemsDialog from '../Items/MoveItemsDialog'

interface Props {
  item: Item
  setItems: Dispatch<SetStateAction<Item[]>>
}

interface FormValues {
  waste_amount: number
}

const ManageItemsButton = ({ item, setItems }: Props) => {
  const { currentProject } = useAppContext()
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const confirmDeletion = useConfirmaDialogStableTarget()
  const { prompt, result, reset } = useConfirmDialogUnStableTarget()
  const [confirmTriggered, setconfirmTriggered] = useState(false)
  const [pendingDeletion, setPendingDeletion] = useState<FormValues | null>(
    null
  )
  // const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)

  useEffect(() => {
    if (result && pendingDeletion) {
      handleMarkItemAsWaste(pendingDeletion)
      reset()
    }
  }, [result, pendingDeletion, reset])

  const handleDelete = async (projectId: string) => {
    const confirmed = await confirmDeletion({
      title: i18n.t('confirmDelete'),
    })

    if (!confirmed) {
      return
    }
    try {
      const deletedItemId = await itemsService.deleteItemById(
        item.id,
        projectId
      )
      if (deletedItemId) {
        toast.success(i18n.t('successGeneric'))
        setItems((oldItems) => oldItems.filter((i) => i.id !== deletedItemId))
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const handleMarkItemAsWaste = async (values: FormValues) => {
    if (values.waste_amount === item.amount && !confirmTriggered) {
      setPendingDeletion(values)
      setconfirmTriggered(true)
      prompt({
        title: i18n.t('confirmDeleteItemAsAmountIsZeroTitle'),
        text: i18n.t('confirmDeleteItemAsAmountIsZero'),
      })
    } else {
      try {
        const response = await itemsService.markItemToWasteByIdAndAmount(
          values.waste_amount,
          item.id,
          currentProject.id
        )
        if (response.status === 200) {
          toast.success(i18n.t('successGeneric'))
          if (response.data.result === 'item_deleted') {
            // item was deleted
            const deletedItemId = response.data.data
            setItems((oldItems) =>
              oldItems.filter((i) => i.id !== deletedItemId)
            )
          } else {
            // item was updated
            const updatedItem = response.data.data as Item
            setItems((oldItems) =>
              oldItems.map((i) => (i.id === updatedItem.id ? updatedItem : i))
            )
          }
          setDialogOpen(false)
          setconfirmTriggered(false)
        }
      } catch (error) {
        displayResponseErrorMessage(error)
      }
    }
  }

  return (
    <DropdownMenu /*open={dropdownOpen} onOpenChange={setDropdownOpen}*/>
      <DropdownMenuTrigger
        asChild
        data-testid={`manage-item-button-${item.name}`}
      >
        <Button variant={'ghost'}>
          <Ellipsis />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>{i18n.t('manage')}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <Dialog
          open={dialogOpen}
          onOpenChange={setDialogOpen}
          /*
          onOpenChange={(open) => {
            if (open) {
              setDialogOpen(true)
            } else {
              setDialogOpen(false)
              setDropdownOpen(false)
            }
          }}*/
        >
          <DialogTrigger asChild>
            <DropdownMenuItem
              data-testid="open-item-waste-modal-button"
              onSelect={(event) => {
                event.preventDefault()
              }}
            >
              {i18n.t('markAsWaste')}
            </DropdownMenuItem>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>{i18n.t('markAsWaste')}</DialogTitle>
              <DialogDescription>
                {i18n.t('markAsWasteDescription')}
              </DialogDescription>
            </DialogHeader>
            <Separator className="my-2" />
            <div>
              <p>
                <span className="font-bold">{i18n.t('itemName')}:</span>{' '}
                {item.name}
              </p>
              <p>
                <span className="font-bold">{i18n.t('category')}:</span>{' '}
                {item.category}
              </p>
              <p>
                <span className="font-bold">{i18n.t('unit')}:</span> {item.unit}
              </p>
              <Formik
                initialValues={{
                  waste_amount: item.amount,
                }}
                onSubmit={handleMarkItemAsWaste}
                validationSchema={() =>
                  validationSchemaAddWasteItem(item.amount)
                }
              >
                {({ isSubmitting }) => (
                  <Form className="flex flex-col gap-3 py-4">
                    <div>
                      <Label htmlFor="waste_amount">
                        {i18n.t('amountToWaste')}
                      </Label>
                      <Field
                        type="number"
                        id="waste_amount"
                        name="waste_amount"
                        max={item.amount}
                        min={0}
                        placeholder={i18n.t('amountToWaste')}
                        as={Input}
                      />
                      <FormErrorMessage name="waste_amount" />
                      <Separator className="my-3" />
                      <DialogFooter className="my-2 flex gap-2">
                        <DialogClose asChild>
                          <Button type="button" variant={'destructive'}>
                            {i18n.t('cancel')}
                          </Button>
                        </DialogClose>
                        <PendingSubmitButton
                          buttonText={i18n.t('save')}
                          isSubmitting={isSubmitting}
                        />
                      </DialogFooter>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </DialogContent>
        </Dialog>
        <DropdownMenuSeparator />
        <MoveItemsDialog item={item} setItems={setItems} renderInDropDownMenu />
        <DropdownMenuSeparator />
        <DropdownMenuItem
          onClick={() => handleDelete(currentProject.id)}
          data-testid={`delete-item-button-${item.name}`}
        >
          {i18n.t('delete')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default ManageItemsButton
