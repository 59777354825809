import { Label } from '../ui/label'
import { Input } from '../ui/input'
import i18n from 'src/i18n'
import { toast } from 'react-toastify'
import changePasswordService from 'src/services/Auth/change-password'
import * as Yup from 'yup'
import { Field, Form, Formik } from 'formik'
import FormErrorMessage from './FormErrorMessage'
import PendingSubmitButton from '../Buttons/PendingSubmitButton'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '../ui/card'
import { Separator } from '../ui/separator'

interface SendChangePasswordMailFormValues {
  email: string
}

const initialValues: SendChangePasswordMailFormValues = {
  email: '',
}

const validationSchema = () =>
  Yup.object({
    email: Yup.string()
      .email(i18n.t('emailValidation.invalidFormat'))
      .required(i18n.t('fieldValidation.required')),
  })

const SendChangePasswordMailForm = () => {
  const handleSubmit = async (values: SendChangePasswordMailFormValues) => {
    if (!values.email) {
      toast.error(i18n.t('provideEmail'))
      return
    }
    try {
      const response = await changePasswordService.sendChangePasswordMail({
        email: values.email,
      })
      if (response.status === 200) {
        toast.success(i18n.t('emailSentSuccessfully'))
      } else {
        toast.error(i18n.t('errorOccured'))
      }
    } catch (error: any) {
      if (error.response && error.response.status == 429) {
        toast.error(i18n.t('limiter.changePassword'))
      } else {
        toast.error(i18n.t('errorSendingMail'))
      }
    }
  }
  return (
    <Card className="p-1 w-full max-w-md" data-testid="forgot-password-card">
      <CardHeader>
        <CardTitle className="text-center">
          {i18n.t('setNewPassword')}
        </CardTitle>
        <Separator className="mb-2" />
        <CardDescription className="mb-3">
          {i18n.t('sendChangePasswordMailDescription')}
        </CardDescription>
      </CardHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <CardContent className="flex flex-col gap-3">
              <Label htmlFor="email">{i18n.t('email')}</Label>
              <Field
                type="email"
                id="email"
                name="email"
                placeholder={i18n.t('email')}
                className="bg-white text-black rounded-lg px-3 py-2"
                data-testid="email-input"
                as={Input}
              />
              <FormErrorMessage name="email" />
              <PendingSubmitButton
                buttonText={i18n.t('send')}
                isSubmitting={isSubmitting}
              />
            </CardContent>
          </Form>
        )}
      </Formik>
    </Card>
  )
}

export default SendChangePasswordMailForm
