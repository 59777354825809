import { useNavigate } from 'react-router'
import i18n from 'src/i18n'
import { Button } from '../ui/button'
import { iconAndTextStyling } from 'src/constants'
import { ArrowBigLeft } from 'lucide-react'

const BackButton = () => {
  const navigate = useNavigate()
  return (
    <Button
      variant={'secondary'}
      className={iconAndTextStyling}
      onClick={() => navigate(-1)}
    >
      <ArrowBigLeft /> {i18n.t('back')}
    </Button>
  )
}

export default BackButton
