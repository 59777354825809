import React, { Dispatch, SetStateAction, useState, useEffect } from 'react'
import { Formik, Form, Field } from 'formik'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../ui/dialog'
import { Button } from '../ui/button'
import i18n from 'src/i18n'
import { Label } from '../ui/label'
import { Input } from '../ui/input'
import { Bubble, Item } from 'src/lib/types'
import { toast } from 'react-toastify'
import itemsService from 'src/services/Items/items'
import FormErrorMessage from '../Forms/FormErrorMessage'
import PendingSubmitButton from '../Buttons/PendingSubmitButton'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../ui/tabs'
import { Card, CardContent, CardDescription, CardHeader } from '../ui/card'
import { Edit, Plus } from 'lucide-react'
import { iconAndTextStyling } from 'src/constants'
import { displayResponseErrorMessage } from 'src/lib/utils'
import { useAppContext } from 'src/context/AppProvider'
import { useConfirmDialogUnStableTarget } from 'src/components/ui/confirmDialog'
import {
  validationSchemaAddItem,
  validationSchemaUpdateItem,
} from 'src/lib/validationSchemas'

interface Props {
  bubble?: Bubble
  setItems: Dispatch<SetStateAction<Item[]>>
  item?: Item
}

interface FormValues {
  name: string
  amount: number
  unit: string
  category: string
}

const AddOrUpdateItemDialog = ({ bubble, setItems, item }: Props) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const { currentProject } = useAppContext()
  const projectId = currentProject?.id
  const { prompt, result, reset } = useConfirmDialogUnStableTarget()
  const [pendingDeletion, setPendingDeletion] = useState<FormValues | null>(
    null
  )

  useEffect(() => {
    if (result && pendingDeletion) {
      handleDeleteItem(pendingDeletion)
      reset()
    }
  }, [result, pendingDeletion, reset])

  const handleDeleteItem = async (formValues: FormValues) => {
    try {
      const deletedItemId = await itemsService.markItemOutOfStockById(
        item!.id,
        projectId!
      )
      if (deletedItemId) {
        toast.success(i18n.t('successGeneric'))
        setItems((oldItems) => oldItems.filter((i) => i.id !== deletedItemId))
        setDialogOpen(false)
        setPendingDeletion(null)
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const handleItemSubmit = async (values: FormValues, actions: any) => {
    if (!projectId) {
      toast.error(i18n.t('noProjectDefined'))
      return
    }
    try {
      if (item) {
        if (values.amount === 0) {
          setPendingDeletion(values)
          prompt({
            title: i18n.t('confirmDeleteItemAsAmountIsZeroTitle'),
            text: i18n.t('confirmDeleteItemAsAmountIsZero'),
          })
        } else {
          const updatedItem = await itemsService.updateItemById(
            {
              ...item,
              name: values.name,
              amount: values.amount,
              category:
                values.category === 'null' || values.category === ''
                  ? undefined
                  : values.category,
              unit: values.unit,
            },
            projectId
          )
          if (updatedItem) {
            toast.success(i18n.t('successGeneric'))
            setItems((oldItems) =>
              oldItems.map((o) => (o.id === updatedItem.id ? updatedItem : o))
            )
            setDialogOpen(false)
          }
        }
      } else {
        if (!bubble) {
          toast.error(i18n.t('errorBubbleNonExisting'))
          return
        }
        const newItem = await itemsService.addNewItem(
          {
            ...values,
            category:
              values.category === 'null' || values.category === ''
                ? undefined
                : values.category,
          },
          projectId,
          bubble!.id
        )
        if (newItem) {
          toast.success(i18n.t('successGeneric'))
          setItems((oldItems) => oldItems.concat(newItem))
          actions.resetForm()
          setDialogOpen(false)
        }
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const [selectableCategories, setSelectableCategories] = useState<string[]>([])
  const [selectableUnits, setSelectableUnits] = useState<string[]>([])

  const fetchItemUtils = async () => {
    console.log('fetching item utils')
    if (projectId) {
      try {
        const searchUtils =
          await itemsService.getSearchUtilsByProjectId(projectId)
        setSelectableCategories(searchUtils.categories)
        setSelectableUnits(searchUtils.units)
      } catch (error) {
        console.error(error)
      }
    }
  }

  return (
    <Dialog
      open={dialogOpen}
      onOpenChange={async (open) => {
        if (open) {
          setDialogOpen(true)
          await fetchItemUtils()
        } else {
          setDialogOpen(false)
        }
      }}
    >
      <DialogTrigger
        asChild
        data-testid={
          item ? `modify-item-button-${item.name}` : 'add-new-item-button'
        }
      >
        <Button className={iconAndTextStyling}>
          {item ? (
            <Edit />
          ) : (
            <>
              <Plus /> {i18n.t('addNewItem')}
            </>
          )}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {item
              ? `${i18n.t('modifyItem')} ${item.name}`
              : `${i18n.t('addNewItem')} ${bubble?.title}`}
          </DialogTitle>
        </DialogHeader>
        <Formik
          initialValues={{
            name: item?.name ?? '',
            amount: item?.amount ?? 0,
            unit: item?.unit ?? '',
            category: item?.category ?? '',
          }}
          validationSchema={
            item ? validationSchemaUpdateItem : validationSchemaAddItem
          }
          onSubmit={handleItemSubmit}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <Form className="flex flex-col gap-3 py-4">
              <Label htmlFor="name">{i18n.t('itemName')}</Label>
              <Field
                type="text"
                id="name"
                name="name"
                placeholder={i18n.t('itemName')}
                as={Input}
              />
              <FormErrorMessage name="name" />

              <Label htmlFor="amount">{i18n.t('amount')}</Label>
              <Field
                type="number"
                id="amount"
                name="amount"
                min={0}
                placeholder={i18n.t('amount')}
                as={Input}
              />
              <FormErrorMessage name="amount" />

              <Label htmlFor="unit">{i18n.t('unit')}</Label>
              <Tabs
                defaultValue="existingUnit"
                className="w-full"
                onValueChange={() => {
                  setFieldValue('unit', '')
                }}
              >
                <TabsList className="grid w-full grid-cols-2">
                  <TabsTrigger
                    value="existingUnit"
                    data-testid="existingUnit-tabs-trigger"
                  >
                    {i18n.t('existingUnit')}
                  </TabsTrigger>
                  <TabsTrigger
                    value="newUnit"
                    data-testid="newUnit-tabs-trigger"
                  >
                    {i18n.t('addNewUnit')}
                  </TabsTrigger>
                </TabsList>
                <TabsContent value="existingUnit">
                  <Card>
                    <CardHeader>
                      <CardDescription>
                        {i18n.t('pickExistingUnit')}
                      </CardDescription>
                    </CardHeader>
                    <CardContent>
                      <Select
                        name="unit"
                        onValueChange={(value) => setFieldValue('unit', value)}
                        value={values.unit}
                      >
                        <SelectTrigger data-testid="select-unit-trigger">
                          <SelectValue placeholder={i18n.t('unit')} />
                        </SelectTrigger>
                        <SelectContent>
                          {selectableUnits.map((unit) => (
                            <SelectItem
                              key={unit}
                              value={unit}
                              data-testid={`select-item-unit-${unit}`}
                            >
                              {unit}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </CardContent>
                  </Card>
                </TabsContent>
                <TabsContent value="newUnit">
                  <Card>
                    <CardHeader>
                      <CardDescription>
                        {i18n.t('writeNewUnit')}
                      </CardDescription>
                    </CardHeader>
                    <CardContent>
                      <Field
                        type="text"
                        id="unit"
                        name="unit"
                        list="unitSelect"
                        placeholder={i18n.t('unit')}
                        as={Input}
                      />
                    </CardContent>
                  </Card>
                </TabsContent>
              </Tabs>
              <FormErrorMessage name="unit" />

              <Label htmlFor="category">{i18n.t('category')}</Label>
              <Tabs
                defaultValue="existingCategory"
                className="w-full"
                onValueChange={() => {
                  setFieldValue('category', '')
                }}
              >
                <TabsList className="grid w-full grid-cols-2">
                  <TabsTrigger
                    value="existingCategory"
                    data-testid="existingCategory-tabs-trigger"
                  >
                    {i18n.t('existingCategory')}
                  </TabsTrigger>
                  <TabsTrigger
                    value="newCategory"
                    data-testid="newCategory-tabs-trigger"
                  >
                    {i18n.t('addNewCategory')}
                  </TabsTrigger>
                </TabsList>
                <TabsContent value="existingCategory">
                  <Card>
                    <CardHeader>
                      <CardDescription>
                        {i18n.t('pickExistingCategory')}
                      </CardDescription>
                    </CardHeader>
                    <CardContent>
                      <Select
                        name="category"
                        onValueChange={(value) =>
                          setFieldValue('category', value)
                        }
                        value={values.category}
                      >
                        <SelectTrigger data-testid="select-category-trigger">
                          <SelectValue placeholder={i18n.t('category')} />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value={'null'}>
                            {i18n.t('noChoice')}
                          </SelectItem>
                          {selectableCategories.map((categ) => (
                            <SelectItem
                              key={categ}
                              value={categ}
                              data-testid={`select-item-category-${categ}`}
                            >
                              {categ}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </CardContent>
                  </Card>
                </TabsContent>
                <TabsContent value="newCategory">
                  <Card>
                    <CardHeader>
                      <CardDescription>
                        {i18n.t('writeNewCategory')}
                      </CardDescription>
                    </CardHeader>
                    <CardContent>
                      <Field
                        type="text"
                        id="category"
                        name="category"
                        placeholder={i18n.t('category')}
                        as={Input}
                      />
                    </CardContent>
                  </Card>
                </TabsContent>
              </Tabs>
              <FormErrorMessage name="category" />
              <PendingSubmitButton
                buttonText={i18n.t('save')}
                isSubmitting={isSubmitting}
              />
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default AddOrUpdateItemDialog
