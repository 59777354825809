import React, { useState } from 'react'
import i18n from 'src/i18n'
import { Project } from 'src/lib/types'
import { Button } from '../ui/button'
import {
  allowProjectEnd,
  displayResponseErrorMessage,
  formatDateLocale,
} from 'src/lib/utils'
import { useConfirmaDialogStableTarget } from 'src/components/ui/confirmDialog'
import { toast } from 'react-toastify'
import projectsService from 'src/services/Projects/projects'
import { Label } from '../ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select'
import { useNavigate } from 'react-router'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardTitle,
} from '../ui/card'
import { Separator } from '../ui/separator'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

interface Props {
  project: Project
}

enum ChoiceType {
  MANUAL = 'MANUAL',
  CONTRACT_END = 'CONTRACT_END',
}

const EndProjectForm = ({ project }: Props) => {
  const [choise, setChoise] = useState<ChoiceType | null>(null)
  const [date, setDate] = useState<Date | null>(null)
  const navigate = useNavigate()
  const confirmDeletion = useConfirmaDialogStableTarget()

  const handleArchiveProject = async () => {
    const confirmed = await confirmDeletion({
      title: i18n.t('confirmProjectEnd'),
    })
    if (!confirmed) {
      return
    }
    try {
      let dateToSend
      if (choise === ChoiceType.CONTRACT_END) {
        dateToSend = project.temporary_contract_end_date
      } else {
        dateToSend = date
      }
      if (!dateToSend) {
        toast.error(i18n.t('dateToSendError'))
        return
      }
      console.log('date', dateToSend)
      const response = await projectsService.endProjectById(
        { project_end_date: dateToSend },
        project.id
      )
      if (response.status === 200) {
        toast.success(i18n.t('successGeneric'))
        navigate(
          i18n.t('paths.projects') +
            '/' +
            project.id +
            '/' +
            i18n.t('paths.settings')
        )
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const isDateDisabled = (date: Dayjs): boolean => {
    const today = dayjs()
    const lastDayOfCurrentMonth = today.endOf('month')
    const contractEndDate = dayjs(project.temporary_contract_end_date)

    // Disable all dates before the contract end date
    if (date.isBefore(contractEndDate, 'day')) {
      return true
    }

    // Disable the last day of the current month
    if (date.isSame(lastDayOfCurrentMonth, 'day')) {
      return true
    }

    // Disable all dates more than 3 years from today
    // if (date.isAfter(today.add(3, 'year'), 'day')) {
    // return true
    // }

    // disable all dates that are not the last day of the month
    const isLastDayOfMonth = date.date() === dayjs(date).endOf('month').date()

    return !isLastDayOfMonth
  }

  const isProjectContractENdNextMonthOrLater = dayjs(
    project.temporary_contract_end_date
  ).isAfter(dayjs().endOf('month'))

  return (
    <Card className="px-2">
      <CardTitle className="my-2">
        {i18n.t('chooseProjectEnding.title')}
      </CardTitle>
      <Separator />
      <CardDescription className="my-2">
        {i18n.t('chooseProjectEnding.description')}
      </CardDescription>
      <CardContent className="my-5">
        <div className="my-2">
          <Label>{i18n.t('selectEndDate')}</Label>
        </div>
        <Select
          required
          onValueChange={(value) => setChoise(value as ChoiceType)}
        >
          <SelectTrigger data-testid="select-end-date-trigger">
            <SelectValue placeholder={i18n.t('selectEndDate')} />
          </SelectTrigger>
          <SelectContent>
            {isProjectContractENdNextMonthOrLater && (
              <SelectItem
                value={ChoiceType.CONTRACT_END}
                data-testid={`select-project-end-item-${ChoiceType.CONTRACT_END}`}
              >
                {i18n.t('chooseProjectEnding.contractEnd', {
                  contractEndDate: formatDateLocale(
                    project.temporary_contract_end_date
                  ),
                })}
              </SelectItem>
            )}
            <SelectItem
              value={ChoiceType.MANUAL}
              data-testid={`select-project-end-item-${ChoiceType.MANUAL}`}
            >
              {i18n.t('chooseProjectEnding.chooseManually')}
            </SelectItem>
          </SelectContent>
        </Select>
        {choise === ChoiceType.MANUAL && (
          <div className="my-5 flex flex-col gap-2">
            <Label>{i18n.t('chooseProjectEnding.chooseManually')}</Label>
            <CardDescription>
              {i18n.t('chooseProjectEnding.chooseManuallyDescription', {
                contractEndDate: formatDateLocale(
                  project.temporary_contract_end_date
                ),
              })}
            </CardDescription>
            <div data-testid="project-end-date-picker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disablePast
                  shouldDisableDate={isDateDisabled}
                  onChange={(newValue) => setDate(newValue?.toDate() || null)}
                  format="DD.MM.YYYY"
                  timezone="UTC"
                />
              </LocalizationProvider>
            </div>
          </div>
        )}
        {choise === ChoiceType.CONTRACT_END && (
          <div className="my-4">
            {i18n.t('chooseProjectEnding.selectedContractEndDate')}:{' '}
            <b>{formatDateLocale(project.temporary_contract_end_date)}</b>
          </div>
        )}
      </CardContent>
      <Separator />
      <CardFooter className="my-5">
        <Button
          data-testid="submit-end-project-button"
          onClick={handleArchiveProject}
          disabled={!allowProjectEnd(project)}
        >
          {i18n.t('chooseProjectEnding.confirm')}
        </Button>
      </CardFooter>
    </Card>
  )
}

export default EndProjectForm
