import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/ui/table'
import i18n from 'src/i18n'
import { Item } from 'src/lib/types'
import { formatDate } from 'src/lib/utils'
import DemoManageItemsButton from './DemoManageItemsButton'

interface Props {
  items: Item[]
}

const DemoItemsTable = ({ items }: Props) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{i18n.t('product')}</TableHead>
          <TableHead>{i18n.t('amount')}</TableHead>
          <TableHead>{i18n.t('unit')}</TableHead>
          <TableHead>{i18n.t('category')}</TableHead>
          <TableHead>{i18n.t('lastModified')}</TableHead>
          <TableHead>{i18n.t('manage')}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {items.map((item) => (
          <TableRow key={item.id} data-testid={`items-table-row-${item.name}`}>
            <TableCell className="font-medium">{item.name}</TableCell>
            <TableCell>{item.amount}</TableCell>
            <TableCell>{item.unit}</TableCell>
            <TableCell>{item.category}</TableCell>
            <TableCell>
              {item.latest_editor_email}
              {formatDate(item.updated_at)}
            </TableCell>
            <TableCell className="flex gap-1">
              <DemoManageItemsButton />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default DemoItemsTable
