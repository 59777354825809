import { Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import PendingSubmitButton from 'src/components/Buttons/PendingSubmitButton'
import FormErrorMessage from 'src/components/Forms/FormErrorMessage'
import { getSelectableLanguages } from 'src/components/Register/RegisterForm'
import { Button } from 'src/components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from 'src/components/ui/card'
import { Input } from 'src/components/ui/input'
import { Label } from 'src/components/ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/components/ui/select'
import { useAuth } from 'src/context/authContext'
import i18n from 'src/i18n'
import { User } from 'src/lib/types'
import { displayResponseErrorMessage } from 'src/lib/utils'
import { useConfirmaDialogStableTarget } from 'src/components/ui/confirmDialog'
import { phoneValidationYup } from 'src/lib/validationSchemas'
import { updateLocalStorage } from 'src/services/Auth/token'
import usersService from 'src/services/Users/users'
import * as Yup from 'yup'

interface UpdateUserDataFormValues {
  language: string
}

interface UpdatePhoneFormValues {
  phone: string
}

interface UpdateOrganizationFormValues {
  organization: string
}

const phoneValidationSchema = () =>
  Yup.object().shape({
    phone: phoneValidationYup().required(i18n.t('fieldValidation.required')),
  })

const organizationValidationSchema = () =>
  Yup.object().shape({
    organization: Yup.string().required(i18n.t('fieldValidation.required')),
  })

const ProfileSettingsView = () => {
  const [user, setUser] = useState<User>()
  const navigate = useNavigate()
  const confirmDeletion = useConfirmaDialogStableTarget()
  const { setIsLoggedIn } = useAuth()

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await usersService.getCurrentUser()
        if (user) {
          setUser(user)
        }
      } catch (error) {
        console.log(error)
      }
    }

    fetchUser()
  }, [])

  if (!user) {
    return null
  }

  const handleSubmit = async (values: UpdateUserDataFormValues) => {
    try {
      const res = await usersService.updateCurrentUserData({
        language: values.language,
      })
      if (res.status === 200) {
        const updatedUser = res.data as User
        setUser(updatedUser)
        i18n.changeLanguage(updatedUser.language)
        toast.success(i18n.t('successGeneric'))
        // update profile settings route
        navigate('/' + i18n.t('paths.profile') + '/' + i18n.t('paths.settings'))
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const handlePhoneSubmit = async (values: UpdatePhoneFormValues) => {
    try {
      const res = await usersService.updateCurrentUserData({
        phone: values.phone,
      })
      if (res.status === 200) {
        const updatedUser = res.data as User
        setUser(updatedUser)
        toast.success(i18n.t('successGeneric'))
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const handleOrganizationSubmit = async (
    values: UpdateOrganizationFormValues
  ) => {
    try {
      const res = await usersService.updateCurrentUserData({
        organization: values.organization,
      })
      if (res.status === 200) {
        const updatedUser = res.data as User
        setUser(updatedUser)
        toast.success(i18n.t('successGeneric'))
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const handleAccountDelete = async () => {
    const confirmed = await confirmDeletion({
      title: i18n.t('deleteAccount'),
      text: i18n.t('deleteAccountDescription'),
    })

    if (!confirmed) {
      return
    }
    try {
      const response = await usersService.deleteCurrentUser()
      if (response.status === 200) {
        toast.success(i18n.t('successGeneric'))
        setUser(undefined)
        updateLocalStorage()
        setIsLoggedIn(false)
        navigate('/')
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  return (
    <div data-testid="profile-settings-view">
      <Card x-chunk="dashboard-04-chunk-1">
        <Formik
          initialValues={{
            language: user.language,
          }}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, isSubmitting }) => (
            <Form className="flex flex-col gap-3">
              <CardHeader>
                <CardTitle>{i18n.t('language')}</CardTitle>
                <CardDescription>
                  {i18n.t('profile.languageDescription')}
                </CardDescription>
              </CardHeader>
              <CardContent>
                <Label>{i18n.t('selectLanguage')}</Label>
                <Select
                  required
                  onValueChange={(value) => setFieldValue('language', value)}
                  value={values.language}
                  data-testid="profile-language-select"
                >
                  <SelectTrigger data-testid="profile-language-select-trigger">
                    <SelectValue placeholder={i18n.t('selectLanguage')} />
                  </SelectTrigger>
                  <SelectContent>
                    {getSelectableLanguages().map((lang) => (
                      <SelectItem
                        key={lang.language}
                        value={lang.language}
                        data-testid={`language-select-item-${lang.language}`}
                      >
                        {lang.title}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </CardContent>
              <CardFooter className="border-t px-6 py-4">
                <PendingSubmitButton
                  dataTestId="profile-save-language"
                  buttonText={i18n.t('save')}
                  isSubmitting={isSubmitting}
                />
              </CardFooter>
            </Form>
          )}
        </Formik>
      </Card>

      <Card x-chunk="dashboard-04-chunk-2" className="mt-6">
        <Formik
          initialValues={{ organization: user.organization ?? '' }}
          validationSchema={organizationValidationSchema}
          onSubmit={handleOrganizationSubmit}
        >
          {({ values, isSubmitting }) => (
            <Form className="flex flex-col gap-3">
              <CardHeader>
                <CardTitle>{i18n.t('organization')}</CardTitle>
                <CardDescription>
                  {i18n.t('profile.organizationDescription')}
                </CardDescription>
              </CardHeader>
              <CardContent>
                <Label>{i18n.t('organization')}</Label>
                <Field
                  type="text"
                  name="organization"
                  value={values.organization}
                  placeholder={i18n.t('organization')}
                  data-testid="profile-organization-input"
                  as={Input}
                />
                <FormErrorMessage name="organization" />
              </CardContent>
              <CardFooter className="border-t px-6 py-4">
                <PendingSubmitButton
                  dataTestId="profile-save-organization"
                  buttonText={i18n.t('save')}
                  isSubmitting={isSubmitting}
                />
              </CardFooter>
            </Form>
          )}
        </Formik>
      </Card>

      <Card x-chunk="dashboard-04-chunk-2" className="mt-6">
        <Formik
          initialValues={{ phone: user.phone ?? '' }}
          validationSchema={phoneValidationSchema}
          onSubmit={handlePhoneSubmit}
        >
          {({ values, isSubmitting }) => (
            <Form className="flex flex-col gap-3">
              <CardHeader>
                <CardTitle>{i18n.t('phone')}</CardTitle>
                <CardDescription>
                  {i18n.t('profile.phoneDescription')}
                </CardDescription>
              </CardHeader>
              <CardContent>
                <Label>{i18n.t('phone')}</Label>
                <Field
                  type="tel"
                  name="phone"
                  value={values.phone}
                  placeholder={i18n.t('phone')}
                  data-testid="profile-phone-input"
                  as={Input}
                />
                <FormErrorMessage name="phone" />
              </CardContent>
              <CardFooter className="border-t px-6 py-4">
                <PendingSubmitButton
                  dataTestId="profile-save-phone"
                  buttonText={i18n.t('save')}
                  isSubmitting={isSubmitting}
                />
              </CardFooter>
            </Form>
          )}
        </Formik>
      </Card>
      <div className="my-4">
        <Link to={i18n.t('paths.forgotpassword')} className="underline">
          {i18n.t('forgotPassword')}
        </Link>
      </div>
      <div className="my-4">
        <Button
          data-testid="delete-current-user-button"
          variant={'destructive'}
          onClick={handleAccountDelete}
        >
          {i18n.t('deleteAccount')}
        </Button>
      </div>
    </div>
  )
}

export default ProfileSettingsView
