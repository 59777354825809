import React, { Dispatch, SetStateAction, useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogHeader,
  DialogTitle,
} from '../ui/dialog'
import { Button } from '../ui/button'
import i18n from 'src/i18n'
import { Label } from '../ui/label'
import { Input } from '../ui/input'
import { Blueprint, Bubble } from 'src/lib/types'
import bubblesService from 'src/services/Bubbles/bubbles'
import { toast } from 'react-toastify'
import { Field, Form, Formik } from 'formik'
import { validationSchemaAddOrUpdateBubble } from 'src/lib/validationSchemas'
import PendingSubmitButton from '../Buttons/PendingSubmitButton'
import FormErrorMessage from '../Forms/FormErrorMessage'
import { iconAndTextStyling } from 'src/constants'
import { MapPin, PencilRuler } from 'lucide-react'
import { displayResponseErrorMessage } from 'src/lib/utils'
import DisplaySingleBlueprintImage from './DisplaySingleBlueprintImage'
import { useParams } from 'react-router'
import { useAppContext } from 'src/context/AppProvider'

export interface Coordinates {
  x: number
  y: number
}

interface Props {
  bubble?: Bubble // if bubble is null => add operation, else update
  blueprint: Blueprint
  setSelectedBlueprintBubbles?: Dispatch<SetStateAction<Bubble[]>>
  bubbles?: Bubble[]
  imageUrl?: string
  suggestRefreshAfterOperation?: boolean
}

const AddOrUpdateBubbleDialog = ({
  bubble,
  blueprint,
  setSelectedBlueprintBubbles,
  bubbles,
  imageUrl,
  suggestRefreshAfterOperation,
}: Props) => {
  const [coordinates, setCoordinates] = useState<Coordinates | undefined>(
    bubble
      ? {
          x: bubble.x,
          y: bubble.y,
        }
      : undefined
  )
  const { projectId } = useParams()
  const handleClick = (
    event: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    const boundingRect = event.currentTarget.getBoundingClientRect()
    const x = (event.clientX - boundingRect.left) / boundingRect.width
    const y = (event.clientY - boundingRect.top) / boundingRect.height
    setCoordinates({ x, y })
  }
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  const { setRenderRefreshAlert } = useAppContext()

  const handleSubmit = async (values: { title: string }, actions: any) => {
    try {
      if (!coordinates || !coordinates.x || !coordinates.y) {
        toast.error(i18n.t('mustProvideLocation'))
        return
      }

      if (!projectId) {
        toast.error(i18n.t('projectNotDefined'))
        return
      }
      if (!bubble) {
        const newBubble = await bubblesService.addNewBubble(
          {
            title: values.title,
            x: coordinates.x,
            y: coordinates.y,
          },
          projectId,
          blueprint.id
        )
        if (newBubble) {
          toast.success(i18n.t('successGeneric'))
          if (setSelectedBlueprintBubbles) {
            setSelectedBlueprintBubbles((oldBubbles) =>
              oldBubbles.concat(newBubble)
            )
          }
          actions.resetForm()
          setCoordinates(undefined)
          setDialogOpen(false)
          if (suggestRefreshAfterOperation) {
            setRenderRefreshAlert(true)
          }
        } else {
          throw new Error(i18n.t('errorGeneric'))
        }
      } else {
        const updatedBubble = await bubblesService.updateBubbleById(
          {
            ...bubble,
            title: values.title,
            x: coordinates.x,
            y: coordinates.y,
          },
          projectId
        )
        if (updatedBubble) {
          toast.success(i18n.t('successGeneric'))
          if (setSelectedBlueprintBubbles) {
            setSelectedBlueprintBubbles((oldBubbles) =>
              oldBubbles.map((b) =>
                b.id === updatedBubble.id ? updatedBubble : b
              )
            )
          }
          setDialogOpen(false)
        } else {
          throw new Error(i18n.t('errorGeneric'))
        }
      }
    } catch (error) {
      displayResponseErrorMessage(error)
    }
  }

  const textTitle = bubble ? i18n.t('modifyBubble') : i18n.t('addNewBubble')

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger
        asChild
        data-testid={
          bubble
            ? `modify-bubble-button-${bubble.title}`
            : 'add-new-bubble-button'
        }
      >
        <Button className={iconAndTextStyling} variant={'secondary'}>
          {bubble ? <PencilRuler /> : <MapPin />} {textTitle}
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-2xl overflow-y-scroll max-h-screen">
        <DialogHeader>
          <DialogTitle>{textTitle}</DialogTitle>
        </DialogHeader>
        <Formik
          initialValues={{ title: bubble?.title || '' }}
          validationSchema={validationSchemaAddOrUpdateBubble}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="flex flex-col gap-3 py-4">
              <Label>{i18n.t('selectBubbleLocation')}</Label>
              <div data-testid="add-or-update-bubble-image-container">
                <DisplaySingleBlueprintImage
                  blueprint={blueprint}
                  imageSource={imageUrl!}
                  bubbles={bubbles}
                  setBubbles={setSelectedBlueprintBubbles}
                  handleImageClickFunction={handleClick}
                  addBubbleCoordinates={coordinates}
                />
              </div>
              <Label htmlFor="title">{i18n.t('title')}</Label>
              <Field
                type="text"
                id="title"
                name="title"
                placeholder={i18n.t('title')}
                as={Input}
              />
              <FormErrorMessage name="title" />
              <PendingSubmitButton
                buttonText={i18n.t('save')}
                isSubmitting={isSubmitting}
              />
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default AddOrUpdateBubbleDialog
